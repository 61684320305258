import React from "react";
import "./Timeline.css";
import { Col, Row } from "reactstrap";

import { useRef, useEffect } from "react";
import { PiStudent } from "react-icons/pi";
import { MdWorkOutline } from "react-icons/md";
import gsap from "gsap"; // <-- import GSAP
import { useGSAP } from "@gsap/react";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// gsap.registerPlugin(useGSAP, ScrollTrigger);

const TimelineItem = ({ date, title, description, icon }) => {
  return (
    <Row className={`timeline-item`}>
      <Col className="timeline-date box">
        <h4>{date}</h4>
      </Col>
      <Col xs={1} className="work-icon">
        <div>{icon}</div>
      </Col>
      <Col className="timeline-content box">
        <Row>
          <Row>
            <h4>{title}</h4>
          </Row>

          <Row>
            <p>{description}</p>
          </Row>
        </Row>
      </Col>
    </Row>
  );
};

const Timeline = () => {
  const container = useRef();
  useGSAP(
    () => {
      const icons = gsap.utils.toArray(".work-icon");
      icons.forEach((icon) => {
        gsap.to(icon, {
          scale: 1,
          scrollTrigger: {
            trigger: icon,
            start: "bottom bottom",
            end: "top 50%",
            scrub: true,
            // markers: true,
          },
        });
      });
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.to(box, {
          x: 0,
          transition: 1,
          opacity: 1,
          scrollTrigger: {
            trigger: box,
            start: "bottom bottom",
            end: "top 60%",
            scrub: true,
            // markers: true,
          },
        });
      });
    },
    { scope: container }
  );
  return (
    <section id="timeline">
      <h2 className="section-heading-dark">Timeline</h2>
      <hr />
      <div ref={container} className="timeline-container no-scroll-x">
        <div className="line"></div>
        {/* Posledni job */}
        <TimelineItem
          date="2024"
          title="Programování WWW aplikací"
          description={
            "VŠB - TU Ostrava : HTML5, CSS3, JavaScript, PHP, MySQL." +
            "\n" +
            "Frameworky: React, Nette, Bootstrap"
          }
          icon={<PiStudent size={50} className="icon-center" />}
        />
        <TimelineItem
          date="2023 - 2023"
          title="Stavby vedoucí"
          description="Procházka borotínská s.r.o., Borotín, stavbyvedoucí, doprava materiálu,
          komunikace s investory"
          icon={<MdWorkOutline size={50} className="icon-center" />}
        />
        <TimelineItem
          date="2020 - 2023"
          title="CNC ohýbání hliníkovýh profilů"
          description="Alupol s.r.o., Planá nad Lužnicí, výroba zastřešení bazénů, ohýbání hliníkových
          profilů na CNC ohýbacím stroji Thoman"
          icon={<MdWorkOutline size={50} className="icon-center" />}
        />
        {/* <TimelineItem
          date="2017 - 2020"
          title="Servisní technik Xiaomi"
          description="VSP DATA a.s., Tábor, diagnostika a oprava mobilních telefonů Nubia a Xiaomi"
          icon={<MdWorkOutline size={50} className="icon-center" />}
        />
        <TimelineItem
          date="2015 - 2017"
          title="OSVČ"
          description="technik mobilních telefonů diagnostika a oprava mobilních telefonů,
          komunikace se zákazníky, objednávky náhradních dílů"
          icon={<MdWorkOutline size={50} className="icon-center" />}
        />
        <TimelineItem
          date="2011 - 2015"
          title="Servisní technik HTC"
          description="VSP DATA a.s., Tábor, diagnostika a oprava mobilních telefonů HTC"
          icon={<MdWorkOutline size={50} className="icon-center" />}
        /> */}
        {/* Prvni job */}
      </div>
    </section>
  );
};

export default Timeline;
