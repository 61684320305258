const translations = {
  en: {
    "header.home": "Home",
    "header.about": "About",
    "header.projects": "Projects",
    "header.timeline": "Timeline",
    "header.contact": "Contact",

    //hero
    "hero.hi": "Hi, my name is",
    "hero.txt":
      "I am currently studying an online course at the Technical University of Ostrava, which is preparing me for a career as a programmer",
    //about
    "about.heading": "About Me",
    "about.text": "nneco englishj",
    //contact
    "contact-heading": "Contact",
    "contact-email-placeholder": "E-mail",
    "contact-message-placeholder": "Message",
    "contact-button-click": "Sending...",
    "contact-button": "Send",
  },
  cz: {
    //header
    "header.home": "Úvod",
    "header.about": "O mně",
    "header.projects": "Projekty",
    "header.timeline": "Timeline",
    "header.contact": "Kontakt",

    //hero
    "hero.hi": "Ahoj, jmenuji se",
    "hero.txt":
      "nyní studuji online kurz na Technické Univerzitě v Ostravě, který mě připravuje na kariéru programátora",

    //about
    "about.heading": "O mně",
    "about.text": "Něco o mě ale sám nevím co tam napsat",
    //contact
    "contact-heading": "Kontakt",
    "contact-email-placeholder": "E-mail",
    "contact-message-placeholder": "Zpráva",
    "contact-button-click": "Odesílání...",
    "contact-button": "Odeslat",
    "contact-alert-success": "Váš dotaz byl úspěšně odeslán !",
    "contact-alert-fail":
      "Vaše zpráva nebyla odeslána - chyba kontaktního formuláře.      Prosím kontaktujte mě přímo na :",
  },
};

export default translations;
