import React, { useState, useRef, useContext } from "react";
import { Button } from "reactstrap";
import "./Cards.css";
import { RiExternalLinkLine } from "react-icons/ri";
import { RiGithubLine } from "react-icons/ri";
import { RiPlayLine } from "react-icons/ri";
import { useGSAP } from "@gsap/react";
import gsap from "gsap"; // <-- import GSAP
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ModalWindow from "./ModalWindow";
import { LanguageContext } from "./LanguageContext";
import dataProjects from "./data/dataProjects";
gsap.registerPlugin(useGSAP, ScrollTrigger);

const Cards = () => {
  const [showModal, setShowModal] = useState();
  const [modalHead, setModalHead] = useState();
  const [modalBody, setModalBody] = useState();
  const { lang } = useContext(LanguageContext);
  const workcontainer = useRef();
  useGSAP(
    () => {
      const works = gsap.utils.toArray(".one-card");
      works.forEach((work) => {
        gsap.to(work, {
          scale: 1,
          scrollTrigger: {
            trigger: work,
            start: "top bottom",
            end: "top 80%",
            scrub: true,
            // markers: true,
          },
        });
      });
    },
    { scope: workcontainer }
  );
  const handleModal = (obsah, hlava) => {
    setShowModal(true);
    setModalBody(obsah);
    setModalHead(hlava);

    setTimeout(() => {
      setShowModal(false);
    }, 30);
  };

  return (
    <div id="projects">
      <h2 className="section-heading">
        {lang === "cz" ? "Projekty" : "Projects"}
      </h2>
      <hr className="white" />
      <div ref={workcontainer} id="card">
        {dataProjects.map((x) => (
          <div key={x.id} className="one-card">
            <div className="txt-img">
              <div className="info">
                <h3>{lang === "cz" ? x.name : x.nameEN}</h3>
                <p>
                  {lang === "cz" ? "Vytvořeno pomocí:" : "Made with:"}
                  {x.technologies.map((ele, index) => (
                    <span className="tech" key={index}>
                      <img src={ele} width={30} height={30} />
                    </span>
                  ))}
                </p>
                <p>{lang === "cz" ? x.info : x.infoEN}</p>
                <br></br>
              </div>
              <div className="project-img">
                <div className="web-window">
                  <img className="project-image" src={x.image}></img>
                </div>
              </div>
            </div>
            <div className="card-btn">
              {x.icon === "play" ? (
                <Button onClick={() => handleModal(x.project, x.name)}>
                  <RiPlayLine size={25} />
                </Button>
              ) : (
                <a className="card-link" href={x.project} target="_blank">
                  <Button>
                    <RiExternalLinkLine size={25} />
                  </Button>
                </a>
              )}
              <a href={x.git} target="_blank">
                <Button>
                  <RiGithubLine size={25} />
                </Button>
              </a>
            </div>
          </div>
        ))}
        <ModalWindow open={showModal} body={modalBody} head={modalHead} />
      </div>
    </div>
  );
};

export default Cards;
