import "./App.css";
import { Container } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import ParticleEffect from "./components/Particles";
import Cards from "./components/Cards";
import Timeline from "./components/Timeline";
import { useState, useEffect, useContext } from "react";
import Header2 from "./components/Header2";
import About from "./components/About";
import { LanguageProvider } from "./components/LanguageContext";
import Kontakt from "./components/Kontakt";

function App() {
  // const windowWidth = window.innerWidth;

  return (
    <div>
      <LanguageProvider>
        <div className="background-hero">
          {/* {windowWidth > 767 ? <ParticleEffect /> : <></>} */}
          <ParticleEffect />
          <div>
            <Header2 />
            <main className="abs">
              <div>
                <div className="dark">
                  <Container>
                    <Hero />
                  </Container>
                </div>
              </div>

              <div className="no-scroll-x">
                <div className="back-light">
                  <div className="card-back-light"></div>
                  <Container>
                    <About />
                  </Container>
                  <div className="card-back-light-180"></div>

                  <div className="card-back-dark">
                    <Container>
                      <Cards />
                    </Container>
                  </div>
                  <div className="card-back-light"></div>

                  <Container>
                    <Timeline />
                  </Container>
                  <div className="card-back-light-180"></div>
                  <div className="card-back-dark">
                    <Container>
                      <Kontakt />
                      <Footer />
                    </Container>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </LanguageProvider>
    </div>
  );
}

export default App;
