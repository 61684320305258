import Apple from "../Apple";
import Iss from "../Iss";
import html from "../icons/html.png";
import css from "../icons/css.png";
import js from "../icons/js.png";
import react from "../icons/react.png";
const dataProjects = [
  {
    id: 1,
    name: "Počítání jablíček",
    nameEN: "Apple counting",
    info: "Aplikace pro moji dceru (5 let), která ráda počítá. Když jsem se učil s C#, udělal jsem něco pro nás oba. Nyní předělaná Javascriptem a Reactem.",
    infoEN:
      "An application for my daughter (5 years old) who enjoys counting. When I was learning with C#, I made something for both of us. Now reimagined with JavaScript and React.",
    image: "./images/preview.png",
    icon: "play", // link nebo play podle dostupnosti >> play zde na strance<< jinak link
    project: <Apple />,
    git: "https://github.com/ladislavklejna/Apples",
    technologies: [html, css, js, react],
  },
  {
    id: 2,
    name: "Živé sledování MVS",
    nameEN: "Live ISS tracker",
    info: "Aplikace, která sleduje aktuální polohu Mezinárodní Vesmírné Stanice, pomocí API.",
    infoEN:
      "An application that tracks the current position of the International Space Station using an API.",
    image: "./images/iss.png",
    icon: "play", // link nebo play podle dostupnosti >> play zde na strance<< jinak link
    project: <Iss />,
    git: "https://github.com/ladislavklejna/ISS",
    technologies: [html, css, js, react],
  },
  {
    id: 3,
    name: "Arapro.cz",
    nameEN: "Arapro.cz",
    info: "Webová stránka, kterou jsem vytvořil pro svého bratra. Chtěl něco statického. Stránka byla vytvořena podle jeho grafického návrhu.",
    infoEN:
      "A website I created for my brother. He wanted something static. The site was built according to his graphic design.",
    image: "./images/arapro.png",
    icon: "link", // link nebo play podle dostupnosti >> play zde na strance<< jinak link
    project: "https://arapro.cz",
    git: "https://github.com/ladislavklejna/bracha-web",
    technologies: [html, css, js, react],
  },
  {
    id: 4,
    name: "Aplikace D&D - ve vývoji",
    nameEN: "The D&D application - in development",
    info: "Máme skvělou partu lidí, se kterými se jednou do měsíce sejdeme, klidně i na celý víkend a hrajeme D&D. Občas házení několika kostkami zdržuje, proto jsem si vytvořil tuto aplikaci. Původně to byly jen virtuální kostky, ale rozhodl jsem se to posunout o něco dál.",
    infoEN:
      "We have a great group of people with whom we gather once a month, sometimes even for a whole weekend, to play D&D. Occasionally, the dice rolling slows us down, so I created this application. Originally, it was just virtual dice, but I decided to take it a step further",
    image: "./images/dndapp.png",
    icon: "link", // link nebo play podle dostupnosti >> play zde na strance<< jinak link
    project: "https://dndborohelper.netlify.app",
    git: "https://github.com/ladislavklejna/dnd-helper",
    technologies: [html, css, js, react],
  },
  {
    id: 5,
    name: "Test anglických slov",
    nameEN: "The English words test",
    info: "V angličtině nejsem úplně přeborník, ale na Technické univerzitě v Ostravě mi byl posytnut txt soubor se slovíčky, které by bylo dobré umět. Ale hrozně mě nebavilo koukat do poznámkového bloku a číst slovíčka. Vyrobil jsem appku, která není limitována OS a tak si můžu procvičovat i na mobilu.",
    infoEN:
      "In English, I'm not exactly an expert, but at the Technical University of Ostrava, I was given a text file with words that would be good to know. However, I didn't enjoy looking at the notepad and reading the words. So, I made an app that isn't limited to an operating system, allowing me to practice even on my mobile.",
    image: "./images/testangl.png",
    icon: "link", // link nebo play podle dostupnosti >> play zde na strance<< jinak link
    project: "https://testpcanglictiny.netlify.app/",
    git: "https://github.com/ladislavklejna/dictionary",
    technologies: [html, css, js, react],
  },
  {
    id: 6,
    name: "Časy VT a NT elektřiny",
    nameEN: "Times for high tariff (VT) and low tariff (NT) electricity",
    info: "Jelikož mi elektrokotel doma netopí v době vysokého tarifu (VT) a kvůli dětem je dobré i často větrat. Udělal jsem si aplikaci primárně do  telefonu, abych věděl kdy VT končí, mohl vyvětrat a následně zapnout kotel.",
    infoEN:
      "Since my electric boiler doesn't heat during the high tariff (VT) period at home, and it's also important to ventilate frequently because of the children, I made an app primarily for my phone. This helps me know when the VT ends so I can ventilate and then turn on the boiler.",
    image: "./images/eonmeter.png",
    icon: "link", // link nebo play podle dostupnosti >> play zde na strance<< jinak link
    project: "https://eonmeter.netlify.app/",
    git: "https://github.com/ladislavklejna/eon",
    technologies: [html, css, js, react],
  },
  {
    id: 7,
    name: "Školní cvičení s formuláři",
    nameEN: "School exercise with forms",
    info: "Za pomoci čisteho Javascriptu a querySelectorů byl vytvořen formulář pro půjčovnu kol.",
    infoEN:
      "Using pure JavaScript and querySelectors, a form for bicycle rental has been created.",
    image: "./images/javascriptcv.png",
    icon: "link", // link nebo play podle dostupnosti >> play zde na strance<< jinak link
    project: "https://javascript-cv.netlify.app/",
    git: "https://github.com/ladislavklejna/JavascriptCV",
    technologies: [html, css, js],
  },
];
export default dataProjects;
