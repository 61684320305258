import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { LanguageContext } from "./LanguageContext";
import translations from "./locales/translations";
import "./Hero.css";
import { RiFileDownloadLine } from "react-icons/ri";

const Hero = () => {
  const [changingText, seChangingText] = useState("");
  const { lang } = useContext(LanguageContext);
  const translation = translations[lang];

  let j = 0;
  let poleTextu = ["Frontend", "React", "Web Developer", "JavaScript"];
  const openCV = () => {
    window.open("/images/Ladislav-Klejna-CV.pdf", "_blank");
  };

  const change = (oneText) => {
    let text = oneText;
    let element = "";
    let i = 0;
    //postupne vypsani
    const intervalWrite = setInterval(() => {
      element = element + text.charAt(i);
      seChangingText(element);

      // Zkontrolujeme, zda jsme došli na konec textu
      if (i === text.length - 1) {
        clearInterval(intervalWrite);
        // Zastavíme interval po dosažení konce textu
        setTimeout(() => {
          const intervalDelete = setInterval(() => {
            if (element.length > 0) {
              element = element.slice(0, -1);
              seChangingText(element);
            } else {
              clearInterval(intervalDelete);
              if (j > poleTextu.length - 1) {
                j = 0;
              }
              change(poleTextu[j]);
            }
          }, 50);
        }, 1000);
      }
      i++;
    }, 150);
    j++;
  };

  useEffect(() => {
    change(poleTextu[j]);
  }, []);

  return (
    <div className="hero" id="hero">
      <div id="hero-title-lada" className={`heroheading `}>
        <h2>{translation["hero.hi"]}</h2>
        <h1>Ladislav Klejna</h1>
      </div>
      <br />
      <div className="herotext">
        <h2>{translation["hero.txt"]}</h2>
      </div>
      <div className="one">
        <img className="heroImage" src="./images/portfoliofoto.png" />
      </div>
      <div className="hero-changing-text">
        <h3>[{changingText}]</h3>
      </div>
      <Button onClick={openCV} className="buttonMore hightlight">
        {<RiFileDownloadLine size={25} />} CV
      </Button>
      <div className="mouse-position">
        <div className="scroll-downs">
          <div className="mousey">
            <div className="scroller"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
