import React, { useContext } from "react";
import "./About.css";
import { LanguageContext } from "./LanguageContext";
import translations from "./locales/translations";

const About = () => {
  const { lang } = useContext(LanguageContext);
  const translation = translations[lang];

  return (
    <div id="about" className="about">
      <h2 className="section-heading ope">{translation["about.heading"]}</h2>

      <hr />
      <div className="about-text">
        {lang === "cz" ? (
          <>
            <p>
              Ahoj, jmenuji se Láďa. Jsem Frontendový samouk. Ve svém volném
              čase pracuji na webových aplikacích v JavaScriptu a Reactu. Rád
              vytvářím užitečné aplikace, které mně nebo mému okolí usnadňují
              život a také, při kterých se naučím něco nového.
            </p>
            <p>
              Nyní studuji online kurz Programátor WWW aplikací. Doufám, že
              zdokonalím své znalosti a získám svou první pracovní příležitost
              😉
            </p>
            <p>
              Když zrovna nekóduji, trávím čas se svou rodinou v Borotíně u
              Tábora. Rád jezdím na kole, chodím na procházky a výlety do
              přírody.
            </p>
          </>
        ) : (
          <>
            <p>
              Hello, my name is Láďa. I'm a self-taught frontend developer. In
              my free time, I work on web applications in JavaScript and React.
              I enjoy creating useful applications that make life easier for
              myself or those around me, and also ones that help me learn
              something new.
            </p>
            <p>
              I am currently studying an online course in Web Application
              Programming. I hope to improve my skills and land my first job
              opportunity 😉
            </p>
            <p>
              When I'm not coding, I spend time with my family in Borotín. I
              enjoy cycling, going for walks, and taking trips into nature.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default About;
