import React from "react";
import "./Footer.css";
import { Row, Col } from "reactstrap";
import { useContext } from "react";
import { LanguageContext } from "./LanguageContext";
// import {
//   RiGithubLine,
//   RiPhoneLine,
//   RiMessengerLine,
//   RiMailLine,
//   RiLinkedinBoxLine,
//   RiWhatsappLine,
// } from "react-icons/ri";

const Footer = () => {
  const { lang } = useContext(LanguageContext);
  const size = 30;
  return (
    <footer className="footer">
      <Row>
        <Col xs={12} md={8} className="text-center offset-md-2">
          <h6>
            {lang === "en"
              ? "Made with ❤️️ and a bit of 🧠 too..😁"
              : "Vytvořeno ❤️️ a taky trochu 🧠 ...😁"}{" "}
          </h6>
        </Col>
      </Row>
      {/* <Row>
        <Col className="footer-icons text-center">
          <a href="https://github.com/ladislavklejna">
            <RiGithubLine className="footer-one-icon" size={size} />
          </a>
          <a href="https://www.linkedin.com/in/ladislav-klejna/">
            <RiLinkedinBoxLine className="footer-one-icon" size={size} />
          </a>
          <a href="tel:723450723">
            <RiPhoneLine size={size} />
          </a>
          <a href="mailto:ladislavklejna@gmail.com">
            <RiMailLine size={size} />
          </a>
          <a href="https://m.me/ladislavklejna">
            <RiMessengerLine size={size} />
          </a>
          <a href="https://wa.me/723450723">
            <RiWhatsappLine size={size} />
          </a>
        </Col>
      </Row> */}
    </footer>
  );
};

export default Footer;
