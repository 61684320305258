import React, { useState, useEffect, useContext } from "react";
import translations from "./locales/translations.jsx";
import { LanguageContext } from "./LanguageContext";
import "./Header2.css";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Row,
  Col,
} from "reactstrap";
import { Link, Events, animateScroll as scroll, scrollSpy } from "react-scroll";

function Header2(args) {
  let pole1 = ["</>"];
  let pole2 = ["adislav Klejna"];
  const { lang, toggleLanguage } = useContext(LanguageContext);
  const [isOpen, setIsOpen] = useState(false);
  const translation = translations[lang];
  const [isTitleVisible, setIsTitleVisible] = useState(true);
  const [brand, setBrand] = useState(pole1);
  let velikostOkna;
  let rollMore = 0;
  const toggle = () => {
    if (window.innerWidth <= 767) {
      setIsOpen(!isOpen);
    }
  };
  const handleScroll = () => {
    const menu = document.getElementById("sticky-menu"); // Změňte podle ID vašeho sticky menu
    const title = document.getElementById("hero-title-lada"); // Změňte podle ID vašeho nadpisu

    if (menu && title) {
      const menuRect = menu.getBoundingClientRect();
      const titleRect = title.getBoundingClientRect();

      if (titleRect.top < menuRect.height) {
        setIsTitleVisible(false);
      } else {
        setIsTitleVisible(true);
      }
    }
  };
  useEffect(() => {
    // Přidání posluchače scrollu při načtení komponenty
    window.addEventListener("scroll", handleScroll);
    velikostOkna = window.innerWidth;
    // Odstranění posluchače scrollu při odmontování komponenty
    if (velikostOkna < 768) {
      rollMore = -20;
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    // Registering the 'begin' event and logging it to the console when triggered.
    Events.scrollEvent.register("begin", (to, element) => {
      console.log("begin", to, element);
    });

    // Registering the 'end' event and logging it to the console when triggered.
    Events.scrollEvent.register("end", (to, element) => {
      console.log("end", to, element);
    });

    // Updating scrollSpy when the component mounts.
    scrollSpy.update();

    // Returning a cleanup function to remove the registered events when the component unmounts.
    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  // Defining functions to perform different types of scrolling.
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const scrollToBottom = () => {
    scroll.scrollToBottom();
  };

  const scrollTo = () => {
    scroll.scrollTo(100); // Scrolling to 100px from the top of the page.
  };

  const scrollMore = () => {
    scroll.scrollMore(100); // Scrolling an additional 100px from the current scroll position.
  };

  // Function to handle the activation of a link.
  const handleSetActive = (to) => {
    console.log(to);
  };
  // console.log(isTitleVisible + " title visble");
  const brandAnimation = () => {
    console.log("brandAnimation");
    if (isTitleVisible) {
      setBrand(pole1);
    } else {
      setBrand(pole2);
    }
  };
  useEffect(() => {
    brandAnimation();
  }, [isTitleVisible]);

  return (
    <div className="xxx" id="sticky-menu">
      <Navbar className="heed" expand="md">
        <NavbarBrand href="/">L{brand}</NavbarBrand>
        <NavbarToggler onClick={toggle}>
          <input
            type="checkbox"
            checked={isOpen}
            role="button"
            aria-label="Display the menu"
            className="menu"
          />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar className="abs">
          <Nav className="me-auto cntr" navbar>
            <NavItem className="color-link">
              <Link
                activeClass="active"
                to="hero"
                spy={true}
                smooth={true}
                delay={100}
                offset={-100}
                duration={250}
                onSetActive={handleSetActive}
                onClick={toggle}
              >
                {translation["header.home"]}
              </Link>
            </NavItem>
            <NavItem className="color-link">
              <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                delay={100}
                offset={-100}
                duration={250}
                onSetActive={handleSetActive}
                onClick={toggle}
              >
                {translation["header.about"]}
              </Link>
            </NavItem>
            <NavItem className="color-link">
              <Link
                activeClass="active"
                to="projects"
                spy={true}
                smooth={true}
                delay={100}
                offset={-100}
                duration={250}
                onSetActive={handleSetActive}
                onClick={toggle}
              >
                {translation["header.projects"]}
              </Link>
            </NavItem>
            <NavItem className="color-link">
              <Link
                activeClass="active"
                to="timeline"
                spy={true}
                smooth={true}
                delay={100}
                offset={-100}
                duration={250}
                onSetActive={handleSetActive}
                onClick={toggle}
              >
                {translation["header.timeline"]}
              </Link>
            </NavItem>
            <NavItem className="color-link">
              <Link
                activeClass="active"
                to="kontakt"
                spy={true}
                smooth={true}
                delay={100}
                offset={-100}
                duration={250}
                onSetActive={handleSetActive}
                onClick={toggle}
              >
                {translation["header.contact"]}
              </Link>
            </NavItem>
            <a
              className="color-link"
              target="_blank"
              href="https://github.com/ladislavklejna"
            >
              <NavItem>GitHub</NavItem>
            </a>

            <div onClick={toggleLanguage} className={`color-link en`}>
              {lang === "cz" ? "English" : "Česky"}
            </div>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Header2;
