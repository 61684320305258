import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

function ModalWindow(props, args) {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  useEffect(() => {
    if (props.open === true) {
      toggle();
    }
  }, [props.open]);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>{props.head}</ModalHeader>
        <ModalBody>{props.body}</ModalBody>
      </Modal>
    </div>
  );
}

export default ModalWindow;
