import React, { useState } from "react";
import { Row, Col, Button, Alert, Spinner } from "reactstrap";
import "./Kontakt.css";
import emailjs from "emailjs-com";
import { LanguageContext } from "./LanguageContext";
import { useContext } from "react";
import translations from "./locales/translations";

const Kontakt = () => {
  const [focusMessage, setFocusMessage] = useState(false);
  const [focusMail, setFocusMail] = useState(false);
  const [rows, setRows] = useState(1);
  const [mail, setMail] = useState("");
  const [noMail, setNoMail] = useState(true);
  const [message, setMessage] = useState("");
  const [noMesssage, setNoMesssage] = useState(true);
  const [visibility, setVisibility] = useState(false);
  const [visibilitySuccess, setVisibilitySuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [disabled, setIsDisabled] = useState(false);
  const { lang } = useContext(LanguageContext);
  const translation = translations[lang];
  //                                                        EMAIL INIT API KEY
  emailjs.init("bPKVxQYxj_lrpdzxC");
  //                                                        EMAIL INIT API KEY
  const refreshPage = () => {
    window.location.reload();
  };
  const handleAlertSuccess = () => {
    setVisibilitySuccess(true);

    setTimeout(() => {
      setVisibilitySuccess(false);
      refreshPage();
    }, 5000);
  };

  const handleAlert = () => {
    setVisibility(true);

    setTimeout(() => {
      setVisibility(false);
    }, 5000);
  };

  const handleText = (e) => {
    setMessage(e);
    if (e !== "") {
      setNoMesssage(false);
    } else {
      setNoMesssage(true);
      setRows(1);
    }
  };
  const handleMail = (e) => {
    setMail(e);
    if (e !== "") {
      setNoMail(false);
    } else {
      setNoMail(true);
    }
  };
  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      setRows(rows + 1);
    }
  };
  const handleFocus = () => {
    setFocusMessage(true);
    // setRows(7);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    sendEmail();
    setIsLoading(true);
  };
  console.log();

  // ODESLAT EMAIL
  const sendEmail = () => {
    const emailParams = {
      from_name: mail,
      to_name: "PORTFOLIO",
      subject: "PORTFOLIO",
      email: mail,
      message: message,
    };

    emailjs
      //.send( service_id, template_ID)
      .send("service_cfoeup5", "template_y7u6bvc", emailParams)
      .then((response) => {
        // console.log("E-mail byl úspěšně odeslán!", response);
        handleAlertSuccess();
      })
      .catch((error) => {
        // console.error("Chyba při odesílání e-mailu:", error);
        handleAlert();
        // setAlertMessage(
        //   "Vaše zpráva nebyla odeslána - chyba kontaktního formuláře"
        // );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div id="kontakt">
      <h2 className="section-heading">{translation["contact-heading"]}</h2>
      <hr className="white" />
      <div className="inputs">
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} lg={8} className="email-container offset-lg-2">
              <div className={`email-box  ${focusMail ? "focus-mail" : ""}`}>
                <input
                  autoComplete="off"
                  type="email"
                  name=""
                  id="email"
                  className=""
                  required
                  placeholder={translation["contact-email-placeholder"]}
                  onFocus={() => setFocusMail(true)}
                  onBlur={() => setFocusMail(!noMail)}
                  onChange={(e) => handleMail(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col
              xs={12}
              lg={8}
              className={`message-container offset-lg-2  ${
                focusMessage ? "fit" : ""
              }`}
            >
              <div
                className={`message-box  ${focusMessage ? "focus-msg" : ""}`}
              >
                <textarea
                  type="text"
                  name=""
                  id="message"
                  placeholder={translation["contact-message-placeholder"]}
                  rows={rows}
                  required
                  minLength={5}
                  onKeyDown={handleEnterPress}
                  onFocus={handleFocus}
                  onBlur={() => setFocusMessage(!noMesssage)}
                  onChange={(e) => handleText(e.target.value)}
                ></textarea>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Button className="buttonMore" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <Spinner size="sm" /> {translation["contact-button-click"]}
                  </>
                ) : (
                  <>{translation["contact-button"]}</>
                )}
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col className="offset-md-3 text-center" md={6}>
              <Alert color="danger" isOpen={visibility}>
                {translation["contact-alert-fail"]}
                <br />
                <a href="mailto:ladislavklejna@gmail.com">
                  ladislavklejna@gmail.com
                </a>
              </Alert>
              <Alert color="success" isOpen={visibilitySuccess}>
                {translation["contact-alert-success"]}
              </Alert>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
};

export default Kontakt;
